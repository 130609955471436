export default {
	home: '首页',
	namex: '个人信息',
	notice_list: '公告管理',
	notice_edit: '消息编辑',
	login: '登录',
	echarts: '首页数据',




	// //活动
	// scadmin: '活动管理',
	// hd_details: '活动详情',
	// hd_admin: '活动管理',
	// hd_add: '活动新增',


	//统计
	stati: '统计中心',
	stati_menmber: '会员转化分析',
	stati_area: '会员区域分析',
	stati_trend: '趋势分析',
	stati_indent: '订单统计',
	category:'分类报表',
	qiangdan:'抢单统计',
	stati_paiming:'排名统计',
	stati_time:'时效统计',
	stati_all: '综合统计',
	stati_list: "外修清单",
	org_waixiu: "机构外修清单",
	stati_wusun: "物损清单",
	org_wusun: "机构物损清单",
	state_dingsun: "定损外修报表",
	state_dingsunws: "定损物损报表",
	state_waixiu: "外修报表",
	state_wusun: "物损报表",
	state_zonghe:"综合报表",
	dashboard:'经营驾驶舱',
	comprehensive:'综合走势',
	loss_reduction_analysis:'外修减损分析',
	loss_success_analysis:'外修成功分析',



	//系统设置
	sysgy: '系统设置',
	sort_set: '支付方式',

	sort_news: '短信通知模板',
	deal_set: '全局设置',

	feedback: '客户反馈',
	owner_agreement: '用户注册协议',

	sms_code: '短信验证码',

	sms_queue: '短信发送记录',
    owner_serveagreement: '用户服务协议',
    owner_privacy: '隐私权政策',



	// 积分
	integral: '财务管理',
	integral_list: '积分明细',
	cash_out_list: '提现申请',
	separate_list: '分账记录',


	// 等级管理
	level: '等级管理',
	vip_level: '平台会员等级',
	shop_level: '门店用户等级',


	//客户管理
	member: '客户管理',
	insurance_user_manage: '保险用户管理',
	insurance_company_manage: '保险公司管理',
	external_users_manage: '外修分配',
	external_business_manage: '平台外修商',
	parter_business_manage:'合作外修商',
	recovery_business_manage: '平台回收商',
	parter_recovery_business_manage: '合作回收商',
	recovery_business_manage_user: '回收商分配',
    thingdamage: '平台物损商',
	parter_thingdamage: '合作物损商',
    thingdamage_user: '物损分配',
	rep_business_manage: '维修厂管理',
	extra_bussiness_parts:'外修商配件匹配新增编辑',
	recover_bussiness_parts:'回收商配件匹配新增编辑',
     more_bussiness_area:'机构区域匹配',
	org_appoint_list:'维修厂指派',
	org_appoint_ws_list:'物损一询商',
	user_area_cat_spare:'外修区域项目匹配',

	//业务管理
	bussiness: '业务管理',
	external_order: '外修单管理',
	fitting_match: '外修配件分配',
	fitting_match_recover: '回收配件分配',
	organization_area: '物损商区域分配',
	recovery_business_area: '回收商区域分配',
	extra_organization_area: '外修商区域分配',
	wusun_order: '物损单管理',
	recovery_order: '回收单管理',
	recover_fitting_manage: '回收配件管理',
	user_area_rec_manage:'合作回收商',
	rec_bussiness_area:'回收商分配',
	wusun_fitting_manage: '物损配件管理',
	extra_fitting_manage: '外修配件管理',
	wu_fitting_manage:'无外修商工单',
	region_fitting_manage:'区域异常工单',
    rule_opening: '开标中标',
    kbxy: '开标协议',
	system_area_manage: '系统区域管理',
	brand_car_manage: '品牌车系车型管理',
	message_manage: '留言管理',
    order_details:"外修单详情",
	item_details:"配件单详情",
	wusun_item_details:"物损配件单详情",
	cover_bussiness_area:'覆盖区域分配',

	cover_user_area:'用户管理区域',
	user_area_cat_manage:'合作外修商',



	wuxun_bussiness_parts:'物损商分类匹配新增编辑',

	user_area_cat_ws:'用户区域项目匹配',
	user_area_ws_manage:'合作物损商',
	cover_user_cat_area:'外修区域项目分配',


	// 系统
	sys_manage: '系统管理',
	nav_column: '导航栏目',
	role_list: '角色管理',
	role_management: '权限管理',
	user_list: '用户管理',
	login_log: '登录日志',
	department: '部门管理',
	evaluate: '评价管理',
	station: '岗位管理',
	note: '短信管理',
	reconciliation: '对账管理',
	target_rule_manage: '中标规则管理',
	price_limit: '报价时效',




















	// -----------------------------------------------------//


	//财务
	// finance: '财务',
	// finance_reconc: '财务对账',
	// finance_withdraw: '余额提现',
	// finance_change: '余额变动',
	// finance_integral: '积分流水',
	// finance_commission: '佣金提现流水',
	// finance_comm: '佣金流水',


	// //供应商
	// purch: '供应商',
	// purch_order: '供应商清单',
	// purch_navig: '创建供应商',
	// purch_details: '供应商详情',
	// purch_evalu: '供应商等级',

	// //采购商
	// caig: '采购商',
	// caig_order: '采购商清单',
	// caig_navig: '创建采购商',
	// caig_details: '采购商详情',
	// caig_evalu: '采购商等级',


	// // 机构管理
	// org: '机构管理',
	// org_list: '机构清单',


	//前台供应商

	// // 商品管理
	// commo: 'gys-商品管理',
	// commo_sold: '已下架商品',
	// commo_putaway: '商品上架',
	// commo_inven: '库存设置',
	// commo_list: '商品清单',

	// //销售订单
	// sales: 'gys-销售订单',
	// sales_sales: '销售订单',
	// sales_details: '订单详情',
	// sales_behalf: '代客订单',

	// //客户管理
	// client: 'gys-客户管理',
	// client_mine: '我的客户',
	// client_new: '新增客户',
	// client_details: '客户详情',

	// //个人中心--前台已做
	// personal: 'gys-个人中心',
	// personal_code: '密码重置',
	// personal_discount: '折扣设置',
	// personal_info: '个人首页',
	// personal_binding: '绑定手机',

	// //管理员
	// suppadmin: 'gys-管理员',
	// suppadmin_account: '员工账号',
	// suppadmin_new: '员工新增',
	// suppadmin_details: '员工详情',
	// suppadmin_admin: '员工权限',

	//营销活动
	activity: 'gys-营销活动',
	activity_packet: '红包发放',
	activity_prom: '活动促销',
	activity_details: '活动详情',
	activity_push: '活动发布',



	//广告管理
	ad: '广告管理',
	ad_manage: '广告管理',
	ad_add: '广告新增',
	ad_detail: '广告详情',


	//文章管理
	essar: '文章管理',
	essar_admin: '文章管理',
	essar_new: '文章新增',
	// essar_new_detail: '文章详情',
	essar_class: '文章分类设置',


	// dealerrun: '代理商认证',
	// dealerrun_manage: '代理商认证',
	// mobile_manage: '手机认证',
	// sign_manage: '签约认证',
	// amountBao: '交易明细',
	// withamount: '提现',

}
