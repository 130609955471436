import Main from "@/components/main";
import parentView from "@/components/parent-view";
/**
 * iview-admin中meta除了原生参数外可配置的参数:
 * meta: {
 *  title: { String|Number|Function }
 *         显示在侧边栏、面包屑和标签栏的文字
 *         使用'{{ 多语言字段 }}'形式结合多语言使用，例子看多语言的路由配置;
 *         可以传入一个回调函数，参数是当前路由对象，例子看动态路由和带参路由
 *  hideInBread: (false) 设为true后此级路由将不会出现在面包屑中，示例看QQ群路由配置
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  notCache: (false) 设为true后页面在切换标签后不会缓存，如果需要缓存，无需设置这个字段，而且需要设置页面组件name属性和路由配置的name一致
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  beforeCloseName: (-) 设置该字段，则在关闭当前tab页时会去'@/router/before-close.js'里寻找该字段名对应的方法，作为关闭前的钩子函数
 * }
 */
export default [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login - 登录",
      hideInMenu: true,
    },
    component: () => import("@/view/login/login.vue"),
  },
  {
    path: "/editpwd",
    name: "editpwd",
    meta: {
      title: "Login - 密码安全",
      hideInMenu: true,
    },
    component: () => import("@/view/login/editpwd.vue"),
  },
  {
    path: "/attestation",
    name: "attestation",
    meta: {
      title: "提示",
      hideInMenu: true,
    },
    component: () => import("@/view/attestation/attestation.vue"),
  },
  {
    path: "/",
    name: "_home",
    redirect: "/home",
    component: Main,
    meta: {
      icon: "ios-swap",
      title: "首页",
      hideInMenu: true,
      // notCache: truef
    },
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          title: "Login - 首页",
          hideInMenu: true,
          // notCache: true,
          icon: "md-home",
        },
        component: () => import("@/view/login/echarts.vue"),
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    component: Main,
    meta: {
      icon: "ios-swap",
      title: "个人",
      hideInMenu: true,
    },
    children: [
      {
        path: "namex",
        name: "namex",
        meta: {
          icon: "logo-steam",
          title: "个人信息",
          hideInMenu: true,
        },
        component: () => import("@/view/user/namex.vue"),
      },
    ],
  },
  {
    path: "/stati",
    name: "stati",
    component: Main,
    meta: {
      icon: "ios-podium",
      title: "统计",
      access: [
        "stati_indent",
        "category",
        "qiangdan",
        "stati_time",
        "stati_all",
        "stati_list",
        "stati_wusun",
        "org_waixiu",
        "org_wusun",
        "state_dingsun",
        "state_dingsunws",
        "state_waixiu",
        "state_wusun",
        "state_zonghe",
        "dashboard",
        "comprehensive",
        "loss_reduction_analysis",
        "loss_success_analysis",
      ],
    },
    children: [
      {
        path: "stati_indent",
        name: "stati_indent",
        meta: {
          icon: "ios-git-commit",
          title: "订单统计",
          access: ["stati_indent"],
        },
        component: () => import("@/view/stati/stati_indent.vue"),
      },
      {
        path: "category",
        name: "category",
        meta: {
          icon: "ios-git-commit",
          title: "分类统计",
          access: ["category"],
        },
        component: () => import("@/view/stati/category.vue"),
      },
      {
        path: "qiangdan",
        name: "qiangdan",
        meta: {
          icon: "ios-git-commit",
          title: "抢单统计",
          access: ["qiangdan"],
        },
        component: () => import("@/view/stati/qiangdan.vue"),
      },
      {
        path: "stati_time",
        name: "stati_time",
        meta: {
          icon: "ios-git-commit",
          title: "时效统计",
          access: ["stati_time"],
        },
        component: () => import("@/view/stati/stati_time.vue"),
      },
      {
        path: "stati_all",
        name: "stati_all",
        meta: {
          icon: "ios-git-commit",
          title: "综合统计",
          access: ["stati_all"],
        },
        // component: () => import("@/view/stati/stati_all.vue"),
        component: () => import("@/view/stati/stati_all.vue"),

      },

      {
        path: "stati_list",
        name: "stati_list",
        meta: {
          icon: "ios-git-commit",
					title: "外修清单",
					// 159
          access: ["stati_list"],
        },
        component: () => import("@/view/stati/list.vue"),
			},
      {
        path: "stati_wusun",
        name: "stati_wusun",
        meta: {
          icon: "ios-git-commit",
					title: "物损清单",
          access: ["stati_wusun"],
        },
        component: () => import("@/view/stati/wusun.vue"),
			},
      {
        path: "org_waixiu",
        name: "org_waixiu",
        meta: {
          icon: "ios-git-commit",
					title: "机构外修清单",
					// 159
          access: ["org_waixiu"],
        },
        component: () => import("@/view/stati/org_waixiu.vue"),
			},
      {
        path: "org_wusun",
        name: "org_wusun",
        meta: {
          icon: "ios-git-commit",
					title: "机构物损清单",
          access: ["org_wusun"],
        },
        component: () => import("@/view/stati/org_wusun.vue"),
			},
			{
        path: "state_dingsun",
        name: "state_dingsun",
        meta: {
          icon: "ios-git-commit",
          title: "定损外修报表",
          access: ["state_dingsun"],
        },
        component: () => import("@/view/stati/state_dingsun.vue"),
			},
      {
        path: "state_dingsunws",
        name: "state_dingsunws",
        meta: {
          icon: "ios-git-commit",
          title: "定损物损报表",
          access: ["state_dingsunws"],
        },
        component: () => import("@/view/stati/state_dingsunws.vue"),
			},
			{
        path: "state_waixiu",
        name: "state_waixiu",
        meta: {
          icon: "ios-git-commit",
          title: "外修报表",
          access: ["state_waixiu"],
        },
        component: () => import("@/view/stati/state_waixiu.vue"),
			},
      {
        path: "state_wusun",
        name: "state_wusun",
        meta: {
          icon: "ios-git-commit",
          title: "外修商分析",
          access: ["state_wusun"],
        },
        component: () => import("@/view/stati/state_wusun.vue"),
			},
			{
        path: "state_zonghe",
        name: "state_zonghe",
        meta: {
          icon: "ios-git-commit",
          title: "综合分析",
          access: ["state_zonghe"],
        },
        component: () => import("@/view/stati/state_zonghe.vue"),
      },

      {
        path: "dashboard",
        name: "dashboard",
        meta: {
          icon: "ios-git-commit",
          title: "经营驾驶舱",
          access: ["dashboard"],
        },
        component: () => import("@/view/stati/dashboard.vue"),
      },
      {
        path: "comprehensive",
        name: "comprehensive",
        meta: {
          icon: "ios-git-commit",
          title: "综合走势",
          access: ["comprehensive"],
        },
        component: () => import("@/view/stati/comprehensive.vue"),
      },
      {
        path: "loss_reduction_analysis",
        name: "loss_reduction_analysis",
        meta: {
          icon: "ios-git-commit",
          title: "外修减损分析",
          access: ["loss_reduction_analysis"],
        },
        component: () => import("@/view/stati/loss_reduction_analysis.vue"),
      },
      {
        path: "loss_success_analysis",
        name: "loss_success_analysis",
        meta: {
          icon: "ios-git-commit",
          title: "外修成功分析",
          access: ["loss_success_analysis"],
        },
        component: () => import("@/view/stati/loss_success_analysis.vue"),
      },
      
    ],
  },
  {
    path: "/sysgy",
    name: "sysgy",
    component: Main,
    meta: {
      icon: "ios-settings",
      title: "系统设置",
      access: [
        "deal_set",
        "sort_set",
        "sort_news",
        "deal_set",
        "feedback",
        "sms_code",
        "sms_queue",
        "owner_serveagreement",
        "owner_privacy",
      ],
    },
    children: [
      {
        path: "sort_set",
        name: "sort_set",
        meta: {
          icon: "ios-git-commit",
          title: "支付方式",
          access: ["sort_set"],
        },
        component: () => import("@/view/sysgy/sort_set.vue"),
      },
      // {
      //     path: 'sort_para',
      //     name: 'sort_para',
      //     meta: {
      //         icon: 'md-redo',
      //         title: '提现设置',
      //         access: ['sort_para']
      //     },
      //     component: () =>
      //         import ('@/view/sysgy/sort_para.vue')
      // },
      {
        path: "sort_news",
        name: "sort_news",
        meta: {
          icon: "ios-git-commit",
          title: "短信通知模板",
          access: ["sort_news"],
        },
        component: () => import("@/view/sysgy/sort_news.vue"),
      },
      {
        path: "deal_set",
        name: "deal_set",
        meta: {
          icon: "ios-git-commit",
          title: "全局设置",
          access: ["deal_set"],
        },
        component: () => import("@/view/sysgy/deal_set.vue"),
      },
      {
        path: "feedback",
        name: "feedback",
        meta: {
          icon: "ios-git-commit",
          title: "客户反馈",
          access: ["feedback"],
        },
        component: () => import("@/view/sysgy/feedback.vue"),
      },
      {
        path: "sms_code",
        name: "sms_code",
        meta: {
          icon: "ios-git-commit",
          title: "短信验证码",
          access: ["sms_code"],
        },
        component: () => import("@/view/sysgy/sms_code.vue"),
      },
      {
        path: "sms_queue",
        name: "sms_queue",
        meta: {
          icon: "ios-git-commit",
          title: "短信发送记录",
          access: ["sms_queue"],
        },
        component: () => import("@/view/sysgy/sms_queue.vue"),
      },
      // {
      // 	path: 'owner_agreement',
      // 	name: 'owner_agreement',
      // 	meta: {
      // 		icon: 'ios-paper-outline',
      // 		title: '用户注册协议',
      // 		access: ['owner_agreement']
      // 	},
      // 	component: () => import('@/view/sysgy/owner_agreement.vue')
      // },
      {
        path: "owner_serveagreement",
        name: "owner_serveagreement",
        meta: {
          icon: "ios-git-commit",
          title: "用户服务协议",
          access: ["owner_serveagreement"],
        },
        component: () => import("@/view/sysgy/owner_serveagreement.vue"),
      },
      {
        path: "owner_privacy",
        name: "owner_privacy",
        meta: {
          icon: "ios-git-commit",
          title: "隐私权政策",
          access: ["owner_privacy"],
        },
        component: () => import("@/view/sysgy/owner_privacy.vue"),
      },
    ],
  },
  {
    path: "/level",
    name: "level",
    component: Main,
    meta: {
      icon: "md-ionic",
      title: "等级管理",
      access: ["vip_level"],
    },
    children: [
      {
        path: "vip_level",
        name: "vip_level",
        meta: {
          icon: "ios-git-commit",
          title: "平台会员等级",
          access: ["vip_level"],
        },
        component: () => import("@/view/level/vip_level.vue"),
      },
    ],
  },
  {
    path: "/member",
    name: "member",
    component: Main,
    meta: {
      icon: "ios-contacts",
      title: "客户管理",
      access: [
        "insurance_company_manage",
        "insurance_user_manage",
        "external_business_manage",
        "user_area_cat_manage",
        "external_users_manage",
        "recovery_business_manage",
        "user_area_rec_manage",
        "recovery_business_manage_user",
        "thingdamage",
        "user_area_ws_manage",
        "thingdamage_user",
        "org_appoint_ws_list",
        "org_appoint_list",
        "rep_business_manage",
        "rec_bussiness_area",
      ],
    },
    children: [
      {
        path: "insurance_company_manage",
        name: "insurance_company_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "保险公司管理",
          access: ["insurance_company_manage"],
        },
        component: () => import("@/view/member/insurance_company_manage"),
      },
      {
        path: "insurance_user_manage",
        name: "insurance_user_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "保险用户管理",
          access: ["insurance_user_manage"],
        },
        component: () => import("@/view/member/insurance_user_manage.vue"),
      },
      {
        path: "external_business_manage",
        name: "external_business_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "平台外修商",
          access: ["external_business_manage"],
        },
        component: () => import("@/view/member/external_business_manage.vue"),
      },

      {
        path: "user_area_cat_manage",
        name: "user_area_cat_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "合作外修商",
          access: ["user_area_cat_manage"],
        },
        component: () => import("@/view/member/user_area_cat_manage.vue"),
      },

      {
        path: "external_users_manage",
        name: "external_users_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "外修分配",
          access: ["external_users_manage"],
        },
        component: () => import("@/view/member/external_users_manage.vue"),
      },

      // {
      // 	path: 'parter_business_manage',
      // 	name: 'parter_business_manage',
      // 	meta: {
      // 		notCache: true,
      // 		icon: 'ios-git-commit',
      // 		title: '合作外修商',
      // 		access: ['external_business_manage']
      // 	},
      // 	component: () => import('@/view/member/parter_business_manage.vue')
      // },
      {
        path: "recovery_business_manage",
        name: "recovery_business_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "平台回收商",
          access: ["recovery_business_manage"],
        },
        component: () => import("@/view/member/recovery_business_manage.vue"),
      },

      {
        path: "user_area_rec_manage",
        name: "user_area_rec_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "合作回收商",
          access: ["user_area_rec_manage"],
        },
        component: () => import("@/view/member/user_area_rec_manage.vue"),
      },

      {
        path: "recovery_business_manage_user",
        name: "recovery_business_manage_user",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "回收商分配",
          access: ["recovery_business_manage_user"],
        },
        component: () =>
          import("@/view/member/recovery_business_manage_user.vue"),
      },
      // {
      // 	path: 'parter_recovery_business_manage',
      // 	name: 'parter_recovery_business_manage',
      // 	meta: {
      // 		notCache: true,
      // 		icon: 'ios-git-commit',
      // 		title: '回收商管理',
      // 		access: ['recovery_business_manage']
      // 	},
      // 	component: () => import('@/view/member/parter_recovery_business_manage.vue')
      // },

      {
        path: "thingdamage",
        name: "thingdamage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "平台物损商",
          access: ["thingdamage"],
        },
        component: () => import("@/view/member/thingdamage.vue"),
      },

      {
        path: "user_area_ws_manage",
        name: "user_area_ws_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "合作物损商",
          access: ["user_area_ws_manage"],
        },
        component: () => import("@/view/member/user_area_ws_manage.vue"),
      },


      {
        path: "thingdamage_user",
        name: "thingdamage_user",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "物损分配",
          access: ["thingdamage_user"],
        },
        component: () => import("@/view/member/thingdamage_user.vue"),
      },
      // {
      // 	path: 'parter_thingdamage',
      // 	name: 'parter_thingdamage',
      // 	meta: {
      // 		notCache: true,
      // 		icon: 'ios-git-commit',
      // 		title: '物损商管理',
      // 		access: ['thingdamage']
      // 	},
      // 	component: () => import('@/view/member/thingdamage.vue')
      // },
      {
        path: "more_bussiness_area",
        name: "more_bussiness_area",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "回收商分配",
          access: ["more_bussiness_area"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/more_bussiness_area.vue"),
      },

      {
        path: "rec_bussiness_area",
        name: "rec_bussiness_area",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "回收商分配",
          access: ["rec_bussiness_area"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/rec_bussiness_area.vue"),
      },

      {
        path: "user_area_cat_ws",
        name: "user_area_cat_ws",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "用户管理区域",
          access: ["user_area_cat_ws", "user_area_ws_manage"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/user_area_cat_ws.vue"),
      },
      {
        path: "cover_user_area",
        name: "cover_user_area",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "用户管理区域",
          access: ["cover_user_area"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/cover_user_area.vue"),
      },

      {
        path: "cover_user_cat_area",
        name: "cover_user_cat_area",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "用户区域分类",
          access: ["cover_user_cat_area", "user_area_cat_manage"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/cover_user_cat_area.vue"),
      },
      {
        path: "cover_user_cat_area_spare",
        name: "cover_user_cat_area_spare",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "用户区域分类",
          access: ["cover_user_cat_area_spare", "user_area_cat_manage"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/cover_user_cat_area_spare.vue"),
      },
      {
        path: "user_area_cat_spare",
        name: "user_area_cat_spare",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "区域合作分类",
          access: ["user_area_cat_spare","user_area_cat_manage"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/user_area_cat_spare.vue"),
      },
      {
        path: "cover_bussiness_area",
        name: "cover_bussiness_area",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "覆盖区域分配",
          access: ["cover_bussiness_area"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/cover_bussiness_area.vue"),
      },

      {
        path: "rep_business_manage",
        name: "rep_business_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "维修厂管理",
          access: ["rep_business_manage"],
        },
        component: () => import("@/view/member/rep_business_manage.vue"),
      },
      {
        path: "extra_bussiness_parts",
        name: "extra_bussiness_parts",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "外修商配件匹配新增编辑",
          access: ["extra_bussiness_parts"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/extra_bussiness_parts"),
      },
      {
        path: "wuxun_bussiness_parts",
        name: "wuxun_bussiness_parts",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "物损商分类匹配新增编辑",
          access: ["extra_bussiness_parts"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/wuxun_bussiness_parts"),
      },
      {
        path: "recover_bussiness_parts",
        name: "recover_bussiness_parts",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "回收商配件匹配新增编辑",
          access: ["recover_bussiness_parts"],
          hideInMenu: true,
        },
        component: () => import("@/view/member/recover_bussiness_parts"),
      },
      {
        path: "org_appoint_list",
        name: "org_appoint_list",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "维修厂指派",
          access: ["org_appoint_list"],
        },
        component: () => import("@/view/member/org_appoint_list.vue"),
      },
      {
        path: "org_appoint_ws_list",
        name: "org_appoint_ws_list",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "物损一询商",
          access: ["org_appoint_ws_list"],
        },
        component: () => import("@/view/member/org_appoint_ws_list.vue"),
      },
    ],
  },
  {
    path: "/bussiness",
    name: "bussiness",
    component: Main,
    meta: {
      icon: "ios-rose",
      title: "业务管理",
      access: [
        "external_order",
        "order_details",
        "recovery_order",
        "fitting_match",
        "organization_area",
        "extra_organization_area",
        "recovery_business_area",
        "rule_opening",
        "kbxy",
        "wusun_fitting_manage",
        "recover_fitting_manage",
        "item_details",
        "wusun_item_details",
        "extra_fitting_manage",
        "wu_fitting_manage",
        "region_fitting_manage",
        "message_manage",
        "evaluate",
      ],
    },
    children: [
      {
        path: "external_order",
        name: "external_order",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "外修单管理",
          access: ["external_order"],
        },
        component: () => import("@/view/bussiness/external_order.vue"),
      },
      {
        path: "order_details",
        name: "order_details",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "外修单管理",
          access: ["order_details"],
          hideInMenu: true,
        },
        component: () => import("@/view/bussiness/order_details.vue"),
      },
      {
        path: "recovery_order",
        name: "recovery_order",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "回收单管理",
          access: ["recovery_order"],
        },
        component: () => import("@/view/bussiness/recovery_order.vue"),
      },
      {
        path: "fitting_match",
        name: "fitting_match",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "外修配件分配",
          access: ["fitting_match"],
        },
        component: () => import("@/view/bussiness/fitting_match.vue"),
      },
      {
        path: "organization_area",
        name: "organization_area",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "物损机构区域",
          access: ["organization_area"],
        },
        component: () => import("@/view/bussiness/organization_area.vue"),
      },
      {
        path: "extra_organization_area",
        name: "extra_organization_area",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "外修机构区域",
          access: ["extra_organization_area"],
        },
        component: () => import("@/view/bussiness/extra_organization_area.vue"),
      },
      {
        path: "recovery_business_area",
        name: "recovery_business_area",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "回收商机构区域",
          access: ["recovery_business_area"],
        },
        component: () => import("@/view/bussiness/recovery_business_area.vue"),
      },
      {
        path: "rule_opening",
        name: "rule_opening",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "开标中标",
          access: ["rule_opening"],
        },
        component: () => import("@/view/bussiness/rule_opening.vue"),
      },
      {
        path: "kbxy",
        name: "kbxy",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "开标协议",
          access: ["kbxy"],
        },
        component: () => import("@/view/bussiness/kbxy.vue"),
      },
      {
        path: "wusun_fitting_manage",
        name: "wusun_fitting_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "工单配件管理",
          access: ["wusun_fitting_manage"],
        },
        component: () => import("@/view/bussiness/wusun_fitting_manage.vue"),
      },
      {
        path: "recover_fitting_manage",
        name: "recover_fitting_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "工单配件管理",
          access: ["recover_fitting_manage"],
        },
        component: () => import("@/view/bussiness/recover_fitting_manage.vue"),
      },
      {
        path: "item_details",
        name: "item_details",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "工单配件管理",
          access: ["item_details"],
          hideInMenu: true,
        },
        component: () => import("@/view/bussiness/item_details.vue"),
      },
      {
        path: "wusun_item_details",
        name: "wusun_item_details",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "物损工单配件管理",
          access: ["wusun_item_details"],
          hideInMenu: true,
        },
        component: () => import("@/view/bussiness/wusun_item_details.vue"),
      },
      {
        path: "extra_fitting_manage",
        name: "extra_fitting_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "工单配件管理",
          access: ["extra_fitting_manage"],
        },
        component: () => import("@/view/bussiness/extra_fitting_manage.vue"),
      },

      {
        path: "wu_fitting_manage",
        name: "wu_fitting_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "无外修商工单",
          access: ["wu_fitting_manage"],
        },
        component: () => import("@/view/bussiness/wu_fitting_manage.vue"),
      },
      {
        path: "region_fitting_manage",
        name: "region_fitting_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "区域异常工单",
          access: ["region_fitting_manage"],
        },
        component: () => import("@/view/bussiness/region_fitting_manage.vue"),
      },
      // {
      // 	path: 'brand_car_manage',
      // 	name: 'brand_car_manage',
      // 	meta: {
      // 		notCache: true,
      // 		icon: 'ios-git-commit',
      // 		title: '品牌车系车型管理',
      // 		access: ['brand_car_manage']
      // 	},
      // 	component: () => import('@/view/bussiness/brand_car_manage.vue')
      // },
      {
        path: "message_manage",
        name: "message_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "留言管理",
          access: ["message_manage"],
        },
        component: () => import("@/view/bussiness/message_manage.vue"),
      },
      {
        path: "evaluate",
        name: "evaluate",
        meta: {
          icon: "md-contact",
          title: "评价管理",
          access: ["evaluate"],
        },
        component: () => import("@/view/sys/evaluate.vue"),
      },
    ],
  },
  {
    path: "/sys",
    name: "sys_manage",
    component: Main,
    meta: {
      icon: "ios-cog",
      title: "系统管理",
      access: [
        "nav_column",
        "role",
        "role_management",
        "user_list",
        "login_log",
        "department",
        "station",
        "price_limit",
        "system_area_manage",
      ],
    },
    children: [
      {
        path: "nav_column",
        name: "nav_column",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "导航栏目",
          access: ["nav_column"],
        },
        component: () => import("@/view/sys/column.vue"),
      },
      {
        path: "role",
        name: "role_list",
        meta: {
          icon: "ios-git-commit",
          title: "角色管理",
          access: ["role"],
        },
        component: () => import("@/view/sys/role_list.vue"),
      },
      {
        path: "role_management",
        name: "role_management",
        meta: {
          icon: "ios-git-commit",
          title: "权限管理",
          access: ["role_management"],
        },
        component: () => import("@/view/sys/management.vue"),
      },
      {
        path: "user_list",
        name: "user_list",
        meta: {
          icon: "ios-git-commit",
          title: "用户管理",
          access: ["user_list"],
        },
        component: () => import("@/view/sys/user_list.vue"),
      },
      {
        path: "login_log",
        name: "login_log",
        meta: {
          icon: "ios-git-commit",
          title: "登录日志",
          access: ["login_log"],
        },
        component: () => import("@/view/sys/login_log.vue"),
      },
      {
        path: "department",
        name: "department",
        meta: {
          icon: "ios-git-commit",
          title: "部门管理",
          access: ["department"],
        },
        component: () => import("@/view/sys/department.vue"),
      },
      {
        path: "station",
        name: "station",
        meta: {
          icon: "ios-git-commit",
          title: "岗位管理",
          access: ["station"],
        },
        component: () => import("@/view/sys/station.vue"),
      },

      // 	{
      // 	path: 'note',
      // 	name: 'note',
      // 	meta: {
      // 		icon: 'md-contact',
      // 		title: '短信管理',
      // 		access: ['note']
      // 	},
      // 	component: () => import('@/view/sys/note.vue')
      // },
      // 	{
      // 	path: 'reconciliation',
      // 	name: 'reconciliation',
      // 	meta: {
      // 		icon: 'md-contact',
      // 		title: '对账管理',
      // 		access: ['reconciliation']
      // 	},
      // 	component: () => import('@/view/sys/reconciliation.vue')
      // },
      // 	{
      // 	path: 'target_rule_manage',
      // 	name: 'target_rule_manage',
      // 	meta: {
      // 		icon: 'ios-git-commit',
      // 		title: '中标管理',
      // 		access: ['target_rule_manage']
      // 	},
      // 	component: () => import('@/view/sys/target_rule_manage.vue')
      // },
      {
        path: "system_area_manage",
        name: "system_area_manage",
        meta: {
          notCache: true,
          icon: "ios-git-commit",
          title: "系统区域管理",
          access: ["system_area_manage"],
        },
        component: () => import("@/view/bussiness/system_area_manage.vue"),
      },
      {
        path: "price_limit",
        name: "price_limit",
        meta: {
          icon: "ios-git-commit",
          title: "报价管理",
          access: ["price_limit"],
          hideInMenu: true,
        },
        component: () => import("@/view/sys/price_limit.vue"),
      },
    ],
  },
  {
    path: "/ad",
    name: "ad",
    component: Main,
    meta: {
      icon: "logo-sass",
      title: "广告管理",
      access: ["ad_manage", "ad_add", "ad_detail"],
    },
    children: [
      {
        path: "ad_add",
        name: "ad_add",
        meta: {
          icon: "ios-git-commit",
          title: "新增广告",
          access: ["ad_add"],
        },
        component: () => import("@/view/ad/ad_add.vue"),
      },
      {
        path: "ad_manage",
        name: "ad_manage",
        meta: {
          icon: "ios-git-commit",
          title: "广告管理",
          access: ["ad_manage"],
        },
        component: () => import("@/view/ad/ad_manage.vue"),
      },
    ],
  },
  {
    path: "/essar",
    name: "essar",
    component: Main,
    meta: {
      icon: "ios-book",
      title: "文章管理",
      access: ["essar_admin", "essar_new", "essar_class"],
    },
    children: [
      {
        path: "essar_admin",
        name: "essar_admin",
        meta: {
          icon: "ios-git-commit",
          title: "文章管理",
          access: ["essar_admin"],
        },
        component: () => import("@/view/essar/essar_admin.vue"),
      },
      {
        path: "essar_new",
        name: "essar_new",
        meta: {
          icon: "ios-git-commit",
          title: "文章新增",
          access: ["essar_new"],
        },
        component: () => import("@/view/essar/essar_new.vue"),
      },
      {
        path: "essar_new_detail",
        name: "essar_new_detail",
        meta: {
          icon: "ios-git-commit",
          title: "文章详情",
          hideInMenu: true,
        },
        component: () => import("@/view/essar/essar_new_detail.vue"),
      },
      {
        path: "essar_class",
        name: "essar_class",
        meta: {
          icon: "ios-git-commit",
          title: "文章分类设置",
          access: ["essar_class"],
        },
        component: () => import("@/view/essar/essar_class.vue"),
      },
    ],
  },
];
