export default {
  home: 'Home',
  login: 'Login',
  components: 'Components',
  count_to_page: 'Count-to',
  tables_page: 'Table',
  split_pane_page: 'Split-pane',
  markdown_page: 'Markdown-editor',
  editor_page: 'Rich-Text-Editor',
  icons_page: 'Custom-icon',
  img_cropper_page: 'Image-editor',
  update: 'Update',
  doc: 'Document',
  join_page: 'QQ Group',
  update_table_page: 'Update .CSV',
  update_paste_page: 'Paste Table Data',
  multilevel: 'multilevel',
  directive_page: 'Directive',
  level_1: 'Level-1',
  level_2: 'Level-2',
  level_2_1: 'Level-2-1',
  level_2_3: 'Level-2-3',
  level_2_2: 'Level-2-2',
  level_2_2_1: 'Level-2-2-1',
  level_2_2_2: 'Level-2-2-2',
  excel: 'Excel',
  'upload-excel': 'Upload Excel',
  'export-excel': 'Export Excel',
  tools_methods_page: 'Tools Methods',
  drag_list_page: 'Drag-list',
  i18n_page: 'Internationalization',
  modalTitle: 'Modal Title',
  content: 'This is the modal box content.',
  buttonText: 'Show Modal',
  'i18n-tip': 'Note: Only this page is multi-language, other pages do not add language content to the multi-language package.',
  error_store_page: 'Error Collection',
  error_logger_page: 'Error Logger',
  query: 'Query',
  params: 'Params',
  cropper_page: 'Cropper',
  message_page: 'Message Center',
  tree_table_page: 'Tree Table',
  org_tree_page: 'Org Tree',
  drag_drawer_page: 'Draggable Drawer',
  tree_select_page: 'Tree Selector',
  admin_manage: '后台管理',
  role_manage: '角色管理',
  admin_info: '管理员信息',
  user_manage: '用户',
  user_list: '用户管理',
  org_list: '机构管理',
  department_list: '部门管理',
  job_list: '岗位管理',
  address_list: '地址管理',
  receipt_list: '发票管理',
  inquiry_list: '中华保询价列表',
  inquiry_business_list: '询价配件商列表',
  inquiry_item_list: '询价配件管理',
  inquiry_item_bus: '询价配件报价管理',
  inquiry_log: '询价日志管理',
  bx_business_rule_price_add: '报价时效设置',
  bx_business_rule_supply_add: '供货商规则设置',
  carmodel_add: '车型新增',
  fukan_detail: '复勘详情',
  supply_detail: '供货详情',
  inquiry_detail: '中华保险询价详情',
  system_config: '系统设置',
  bx_business_rule_fukan_add: '复勘规则设置',
  carpart_list: '配件结构管理',
  spare_base_list: '基础配件管理',
  inquiry_attachment_list: '询价附件',
  supply_attachment_list: '供货附件',
  fukan_attachment_list: '复勘附件',
  third_app_list: '三方接口管理',
  insurance_supply_detail: '保险-订单详情',
  fukan_list: '复勘列表',
  fukan_list_dai: '待复勘',
  peijian_detail: '供应商-复勘详情',
  good_tem:'商品发布模板',


  // 供应商
  gy_inquiry_manage:'供应商询价',
  gy_inquiry_list:'供应商询价列表',
  gy_wait_inquiry_list:'供应商待询价',
  gy_inquiry_detail:'供应商询价详情',
}
